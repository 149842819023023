html {
  font-family: $font-family-base;
  font-weight: 100;
  font-size: $font-size-initial;
}

body {
  overflow-x: hidden;
  color: $text-color;
  background: $body-bg;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
}

html,
body,
#app {
  height: 100%;
}

p {
  margin-bottom: 0;
}

a,
.dropdown-item,
.btn {
  cursor: pointer;
  transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out, border-color 0.1s ease-in-out;
  font-family: $font-family-base;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

th {
  font-size: $font-size-base;
  font-weight: $font-weight-normal;
  color: $base-dark-gray;
}

td {
  color: $default;
}

.table td {
  vertical-align: middle;
}

ul ul,
ol ul {
  list-style: none;
}

ul,
ol {
  list-style: none;
  padding-left: 0;
}

.report-style-class {
  height: 100vh;
  margin: 1% auto;
  width: 100%;
}

.st0 {
  fill: #185A7D;
}

.st1 {
  fill: #00BDA4;
}

.st2 {
  fill: #69D2C2;
}


span#valid {
  font-size: 90%;
  color: $orange-dark;
}

span.valid {
  font-size: 90%;
  color: $orange-dark;
}

.load-screen {
  position: fixed;
  background-color: rgba(238, 238, 238, 0.7);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1500;
}

.global-loader {
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

#pageDropDown{
  font-size: 20px !important;
}


