.auth-page {
  height: 100vh;

  .left-column {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-image: url('../assets/login-bolsa.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-color: $base-light-gray;

    @media (max-width: breakpoint-max(sm)) {

      background-size: cover;
    }

  }
}

.widget-auth {
  width: 448px;
  box-shadow: 4px 4px 16px 0 rgba(77, 83, 224, 0.2);

  @media (max-width: breakpoint-max(xs)) {
    width: 85vw;
    height: 100%;
  }

  .auth-header {
    font-size: $headline-large;
    font-weight: $font-weight-semi-bold;
  }

  .logo-block {
    display: flex;
    align-items: center;
    margin: 10px 0px 10px 0px;
    width: 50%;

    @media (max-width: breakpoint-max(xs)) {
      width: 50%;
      margin: 5px 0px 5px 0px;
    }

    .title {
      margin-left: 12px;
      font-size: 20px;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
    }
  }
}

.auth-info {
  padding: 18px 21px;
  border-radius: 0.5rem;
  background: $body-bg;
  color: $base-dark-gray;
}

.form-control {
  color: $base-dark-gray;
}

.dividing-line {
  display: flex;
  flex-direction: row;
  font-size: $font-size-sm;
  font-weight: $font-weight-normal;
}

.dividing-line:before,
.dividing-line:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid $base-middle-gray;
  margin: auto;
}

.social-label {
  margin-right: 1rem;
  font-size: $font-size-sm;
  font-weight: $font-weight-normal;
  color: $base-dark-gray;
}

.socials {
  display: flex !important;
  align-items: end;
  margin: 0 auto;
  a {
    margin: 0 .5rem;

    i{
      color: rgb(107, 133, 158);
    }

    i:before{
      vertical-align: middle;
      
    }
  }
}
